import React from "react"
import { navigate } from "gatsby"
import {
    enviarCodigoRecuperacaoEmail,
    enviarCodigoRecuperacaoENovaSenha,
    handleLogin,
    isLoggedIn
} from "../services/auth"
import * as UtilService from "../services/utils.service";
import Layout from "../components/layout";
const VERIFICAR_EMAIL = 'VERIFICAR_EMAIL';
const INFORMAR_CODIGO_NOVA_SENHA = 'INFORMAR_CODIGO_NOVA_SENHA';

class RecuperarSenha extends React.Component {


    state = {
        view:VERIFICAR_EMAIL,
        data:{
            email:null,
            password:null,
            codigo:null,

        },
        loading:false
    }

    constructor(props) {
        super(props);

        this.handleDataChange = this.handleDataChange.bind(this)
        this.enviarCodigoRecuperacao = this.enviarCodigoRecuperacao.bind(this)
        this.recuperarSenhaComCodigo = this.recuperarSenhaComCodigo.bind(this)
        this.changeView = this.changeView.bind(this)
    }

    enviarCodigoRecuperacao(event){
        event && event.preventDefault()
        this.setState({loading:true})

        enviarCodigoRecuperacaoEmail(this.state.data.email).then(() => {

            this.state.view = INFORMAR_CODIGO_NOVA_SENHA;
        }).catch(err => {
            if (err.status && err.json) {
                err.json().then(body => {
                    if (err.status === 400 && body.message) {
                        alert(body.message)
                    }
                })
            } else {
                alert('Erro ao acessar serviço. Tente mais tarde')

            }
            this.setState({loading: false})

        }).finally(()=>{
            this.setState({loading:false})
        })
    }

    recuperarSenhaComCodigo(event){
        event && event.preventDefault()
        this.setState({loading:true})

        enviarCodigoRecuperacaoENovaSenha(this.state.data.codigo,this.state.data.password).then(() => {
                navigate('/login')
                alert('Senha recuperada com sucesso!')
        }).catch(err => {
            if (err.status && err.json) {
                err.json().then(body => {
                    if (err.status === 400 && body.message) {
                        alert(body.message)
                    }
                })
            } else {
                alert('Erro ao acessar serviço. Tente mais tarde')

            }
            this.setState({loading: false})

        }).finally(()=>{
            this.setState({loading:false})
        })
    }

    handleDataChange(event){
        let target = event.target;
        let userChanges = {
            [target.name]: target.value,
        };
        userChanges = Object.assign(this.state.data,userChanges);
        this.setState({user:userChanges})

    }

    changeView(view){
        this.setState({view:view})
    }
    render() {

        if (isLoggedIn()) {
            navigate(`/`)
        }
        return (
            <Layout>
                <div className="product-area ptb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-4 col-sm-offset-4">
                                { this.state.view===VERIFICAR_EMAIL && (
                                <div className="account-form">

                                    <h4 className="text-center mb-30">Informe o e-mail de sua conta</h4>

                                        <form method="post" onSubmit={event => {
                                            this.enviarCodigoRecuperacao(event)
                                        }}>
                                            <fieldset disabled={this.state.loading}>
                                                <div className="form-group">
                                                    <input type="email" required className="form-control input-lg form-field" placeholder="E-mail" name="email" onChange={this.handleDataChange}/>
                                                </div>

                                                <div className="form-group">
                                                    <button disabled={!this.state.data.email } type="submit" className="btn btn-block btn-round btn-base" >Verificar e-mail</button>
                                                </div>

                                                <div className="mt-30 text-center">
                                                    <a href="javascript:void(0)" onClick={()=>this.changeView(INFORMAR_CODIGO_NOVA_SENHA)}>Já tenho o código!</a>
                                                </div>


                                                {this.state.loading?(
                                                        <div className="mt-30 text-center">
                                                            <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                                                        </div>
                                                    ):
                                                    null }

                                            </fieldset>

                                        </form>


                                </div>
                                        )}

                                { this.state.view===INFORMAR_CODIGO_NOVA_SENHA && (


                                    <div className="account-form">

                                        <h4 className="text-center mb-30">Informe o código enviado no e-mail e sua nova senha</h4>

                                        <form method="post" onSubmit={event => {
                                            this.recuperarSenhaComCodigo(event)
                                        }}>
                                            <fieldset disabled={this.state.loading}>
                                                <div className="form-group">
                                                    <input type="text" required className="form-control input-lg form-field" placeholder="Código recebido no e-mail" name="codigo" onChange={this.handleDataChange}/>
                                                </div>


                                                <div className="form-group">
                                                    <label className="sr-only">Password</label>
                                                    <input type="password" className="form-control input-lg" placeholder="senha" name="password" onChange={this.handleDataChange}/>
                                                </div>


                                                <div className="form-group">
                                                    <button disabled={!this.state.data.codigo || !this.state.data.password } type="submit" className="btn btn-block btn-round btn-base" >Recuperar senha</button>
                                                </div>

                                                {!this.state.loading?(
                                                        <div className="mt-30 text-center">
                                                            <a href="javascript:void(0)" onClick={this.enviarCodigoRecuperacao}>Enviar novo código!</a>
                                                        </div>
                                                    ):
                                                    null }



                                                {this.state.loading?(
                                                        <div className="mt-30 text-center">
                                                            <i className="fa fa-spinner fa-spin fa-2x fa-fw"/>
                                                        </div>
                                                    ):
                                                    null }

                                            </fieldset>

                                        </form>


                                    </div>

                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default RecuperarSenha
